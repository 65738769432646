<template>
  <div class="nomination-completed" v-loading="loading">
    <div class="grid grid-cols-7 justify-between">
      <div class="col-span-6 left-items">
        <div class="items" ref="itemsContainer" v-if="nominations.performance.length > 0">
          <div class="item" v-for="(item, index) in nominations.performance" :key="index" style="">
            <div class="item-box">
              <div class="item-name">{{ item.companyName }}</div> 
              <div class="item-number pt-1">{{ item.nominations ?? "No Record" }}</div>
              <div class="item-label">Nominations</div>
              <div class="item-number pt-1">{{ item.loaded.toFixed(3) ?? "No Record" }} MT</div>
              <div class="item-label">Loaded</div>
            </div>
          </div>        
        </div>
        <div v-else>
          No data found
        </div>
      </div>
      <div class="col-span-1 right-items">
        <div class="total-nominations">
          <div class="total-number">{{ nominations.totalNominations ?? "No Record" }}</div>
          <div class="total-label">Total Nomination</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDashboardNominationCompleted } from "@/services/form";
export default {
  name: "CustomerPerformanceTab",
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: true,
      total: 0,
      barge: 0,
      vessel: 0,
      others: 0,
      nominations:{},
      observer: null,
    }
  },
  // mounted() {
  //   this.updateLastItems();
  //   window.addEventListener('resize', this.updateLastItems);
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.updateLastItems);
  // },
  mounted() {
    this.updateLastItems();
    window.addEventListener("resize", this.updateLastItems);

    this.observer = new MutationObserver(() => {
      this.$nextTick(() => this.updateLastItems());
    });

    const container = this.$refs.itemsContainer;
    if (container) {
      this.observer.observe(container, { childList: true, subtree: true });
      this.observer = new ResizeObserver(this.updateLastItems);
      this.observer.observe(container, { box: "border-box" });
    }
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateLastItems);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    updateLastItems() {
      this.$nextTick(() => {
        const items = this.$refs.itemsContainer?.children;
        if (!items || items.length === 0) return;

        let prevTop = items[0].offsetTop;

        // Remove existing last-in-row classes
        Array.from(items).forEach((item) => item.classList.remove("last-in-row"));

        Array.from(items).forEach((item, index) => {
          if (index > 0 && items[index].offsetTop !== prevTop) {
            items[index - 1].classList.add("last-in-row");
          }
          prevTop = items[index].offsetTop;
        });

        // Ensure the last item always has this class
        items[items.length - 1].classList.add("last-in-row");
      });
    },
    async getData() {
      this.loading = true
      const param = {
        "startDate": this.startDate, 
        "endDate": this.endDate,
      }
      const res = await getDashboardNominationCompleted(param)
      // const res = 
      // {
      //   "msg": "success",
      //   "code": 200,
      //   "data": 
      //   {
      //     "totalNominations" : 110,
      //     "performance": 
      //     [
      //       {
      //         "companyName": "PETROCHINA",
      //         "nominations": 81,
      //         "loaded": 95700,
      //       },
      //       {
      //         "companyName": "GS CALTEX",
      //         "nominations": 13,
      //         "loaded": 11500,
      //       },
      //       {
      //         "companyName": "HD HYUNDAI",
      //         "nominations": 4,
      //         "loaded": 4650,
      //       },
      //       {
      //         "companyName": "PAN NATION",
      //         "nominations": 9,
      //         "loaded": 8360,
      //       },
      //       {
      //         "companyName": "WELBERD CAPITAL",
      //         "nominations": 2,
      //         "loaded": 1320,
      //       },
      //       {
      //         "companyName": "PETROBAS",
      //         "nominations": 1,
      //         "loaded": 794,
      //       },
      //       {
      //         "companyName": "PETROCHINA",
      //         "nominations": 81,
      //         "loaded": 95700,
      //       },
      //       {
      //         "companyName": "GS CALTEX",
      //         "nominations": 13,
      //         "loaded": 11500,
      //       },
      //       {
      //         "companyName": "HD HYUNDAI",
      //         "nominations": 4,
      //         "loaded": 4650,
      //       },
      //       {
      //         "companyName": "PAN NATION",
      //         "nominations": 9,
      //         "loaded": 8360,
      //       },
      //       {
      //         "companyName": "WELBERD CAPITAL",
      //         "nominations": 2,
      //         "loaded": 1320,
      //       },
      //       {
      //         "companyName": "PETROBAS",
      //         "nominations": 1,
      //         "loaded": 794,
      //       },
      //     ]
      //   }
      // }
      // res.data.performance.splice(0, 6);
      this.nominations = res?.data? res.data : {}
      this.loading = false
    }
  }
}
</script>
<style scoped>
.nomination-completed {
  width: 100%;
  padding: 5px;
  color: #666;
  /* overflow-x: auto; */

  .left-items{

  }

  .right-items{
    border-left: 1px solid #A9AEB9;
  }

  .items {
    display: flex;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* padding: 10px; */
    justify-content: flex-start;
    gap: 20px 0px;

  }

  /* .items::after {
    content: "";
    flex: auto;
  } */

  .item {
    /* padding: 0px 20px 0px 0px; */
    /* margin: 0px 0px 0px 25px; */
    /* min-width: 100px; */
    /* height: 62px; */
    
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    border-right: 1px solid #A9AEB9;
    min-width: 170px;
    text-align: center;
    justify-content: center;
  }

  /* .item::after {
    content: "";
    position: static;
    top: 0;
    right: -5px;
    height: 100%;
    width: 3px;
    background-color: #ff0000;
  } */

  .item-name {
    font-size: 14px;
    /* color: #555; */
    white-space: normal;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #fae2b1;
    border-radius: 15px;
    padding: 5px 7px;
    white-space: nowrap;
    display: inline-block;
  }

  .item-number{
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }
  
  .item-label {
    font-size: 12px;
    line-height: 10px;
  }

  .item:last-child {
    border-right: none;
  }

  .last-in-row {
    border-right: none !important;
  }

  .total-nominations {
    text-align: center;
    justify-content: center;
    padding: 5px 0px 0px 5px;
    color: #666;
  }

  .total-number {
    font-size: 60px;
    font-weight: 500;
    line-height: 70px;
  }

  .total-label {
    font-size: 12px;

  }
}
</style>